.spinner {
  animation: rotator 1.4s linear infinite; }
  .spinner .path {
    stroke-dasharray: 187;
    stroke-dashoffset: 0;
    transform-origin: center;
    animation: dash 1.4s ease-in-out infinite; }

@keyframes rotator {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(270deg); } }

@keyframes colors {
  0% {
    stroke: #4285f4; }
  25% {
    stroke: #de3e35; }
  50% {
    stroke: #f7c223; }
  75% {
    stroke: #1b9a59; }
  100% {
    stroke: #4285f4; } }

@keyframes dash {
  0% {
    stroke-dashoffset: 187; }
  50% {
    stroke-dashoffset: 46.75;
    transform: rotate(135deg); }
  100% {
    stroke-dashoffset: 187;
    transform: rotate(450deg); } }
